import React, { useEffect } from 'react';

import { MainLayout, Seo, DailyIcon, RecentArticles, ActivitiesBox } from '../components';


const DonationPage = () => {

  const pageTitle = 'Donații';

  useEffect(
    () => {
      const script = document.createElement('script');
      script.src = 'https://donorbox.org/widget.js';
      script['paypalExpress'] = 'false';
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    },
    []
  );

  return (
    <>
      <Seo title={pageTitle} />
      <MainLayout title={pageTitle}>
        <article>
          {/* <div>
            <h2>Donații cu cartea de credit</h2>
            <iframe 
              src="https://donorbox.org/embed/donatie-22" 
              name="donorbox" 
              title={pageTitle}
              allowpaymentrequest="allowpaymentrequest" 
              seamless="seamless" 
              frameBorder="0" 
              scrolling="no" 
              height="900px" 
              width="100%" 
              style={{ maxWidth: '500px', minWidth: '250px', maHeight: 'none!important' }}
            />
          </div> */}
          <div>
            <h2>Donații folosind e-Transfer</h2>
            <ol>
              <li>
                Transferul trebuie trimis la adresa de email: <b>sfapostolandreimississauga@gmail.com</b>
              </li>
              <li>
                Donatorul este rugat să seteze o întrebare de verificare; se dorește o întrebare generică pentru toată lumea, atunci propunem &quot;Name of the church&quot; iar răspunsul este: <b>ApostolulAndrei</b>
              </li>
              <li>
                Vă rugăm să trimiteți și pomelnicul cu vii și morți pentru a putea fi pomenit la Sf. Liturghie.
              </li>
              <li>
                În câmpul de Message rugăm donatorul să ne scrie numele complet și adresa completă cu numărul de telefon (că așa cere Guvernul) pentru a putea să scriem chitanță de Income Tax.
              </li>
            </ol>
          </div>
          <div>
            <h2>Alte metode de a dona:</h2>
            <ul>
              <li>
                Prin cec pe numele <b>St. Andrew Church Mississauga</b>, la adresa: <b>4030 Dixie Rd. Mississauga, ON, L4W 1M4, Canada</b>
              </li>
              <li>
                Personal, când veniţi la sfintele slujbe
              </li>
            </ul>
          </div>
        </article>
        <aside>
          <DailyIcon />
          <ActivitiesBox />
          <RecentArticles />
        </aside>
      </MainLayout>
    </>
  );
};

export default DonationPage;

